define([
  'common/visual/layout',
  'backbone-ex',
  'darsan',

  "iptv-channel/flist/iptv-channel-flist",
  "iptv-channel/edit/iptv-channel-edit",
  "iptv-channel/new/iptv-channel-new",
  "iptv-channel/stat/iptv-channel-stat.vue",

], function(Layout, Backbone, darsan, flistMod, editMod, newMod, statisticMod)
{
  return Object.create(Layout).extend({
    modParams: {},
    layout: {
      main: {
        edit: editMod,
        list: flistMod,
        new : newMod,
        statistic: statisticMod
      },
    },
  
    create: function(el,options)
    {
      var me = this;
      me.template = "<div id='main-container'></div>";

      var ExRateModel = Backbone.ModelEx.extend({
        idAttribute: "id",
      });

      var url = darsan.makeUrl("","iptv","/iptv/channel");
            
      var ExRateList = Backbone.CollectionExPageable.extend({
        model: ExRateModel,
        url: url,
      });
    
      this.collection = new ExRateList();
      this.modParams.collection = this.collection;

      // return Layout.create.call(me, el, options);
      
      return darsan.get("","iptv","/iptv/group")
            .then(function(tv_group)
            {
              // console.log(tp_traf, tp_static, groups);
              me.tv_group = me.modParams.tv_group = tv_group;
              
              return Layout.create.call(me,el,options);
            });
    }, 

    neededOverlay: function(state,name)
    {
      if (name!="main") throw "No overlay for "+name;

      if ( state._rest && state._rest!="/" )
      {
        if (state._rest.match("/history$")) return "history";
        if (state._rest.match("/statistic")) return "statistic";
        if (state._rest == "/!new") return "new";
        return "edit";
      }
      else
      {
        return "list";
      }
    }
  });
});
