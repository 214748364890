define([
  'common/visual/layout',
  'text-loader!iptv-group/flist/layout.tpl',
  "iptv-group/list/iptv-group-list",

], function(Layout, Template, listMod)
{
  return Object.create(Layout).extend({
    template: Template,
    modParams: {},

    layout: {
      list: listMod,
    },
  
    create: function(el,options)
    {
      this.modParams.collection = options.collection;
      // this.modParams.permissions = options.permissions;
      return Layout.create.call(this,el,options);
    }, 

  });
});
