var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("SimpleTable", {
        ref: "top10",
        attrs: {
          name: "iptv-top10",
          title: "Топ 10 самых популярных каналов",
          columns: _vm.columns,
          divClass: ""
        },
        scopedSlots: _vm._u([
          {
            key: "channel",
            fn: function({ rec }) {
              return [
                _vm._v(
                  "\n     " + _vm._s(_vm.channelName(rec.channel)) + "\n  "
                )
              ]
            }
          },
          {
            key: "rate",
            fn: function({ rec }) {
              return [
                _vm._v(
                  "\n     " + _vm._s((rec.rate * 100).toFixed(2)) + " %\n  "
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("SimpleTable", {
        ref: "bottom10",
        attrs: {
          name: "iptv-bottom10",
          title: "Самые неинтересные каналы",
          columns: _vm.columns,
          divClass: ""
        },
        scopedSlots: _vm._u([
          {
            key: "channel",
            fn: function({ rec }) {
              return [
                _vm._v(
                  "\n     " + _vm._s(_vm.channelName(rec.channel)) + "\n  "
                )
              ]
            }
          },
          {
            key: "rate",
            fn: function({ rec }) {
              return [
                _vm._v(
                  "\n     " + _vm._s((rec.rate * 100).toFixed(2)) + " %\n  "
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }