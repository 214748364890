define([
  'common/visual/layout',
  'text-loader!iptv-channel/flist/layout.tpl',
  "iptv-channel/list/iptv-channel-list",
  "common/search/search",

], function(Layout, Template, listMod, Search)
{
  var searchMod = Object.create(Search).extend({
    metaAddress: ["","iptv","/meta/iptv/channel/search"],
  });
  
  return Object.create(Layout).extend({
    template: Template,
    modParams: {},

    layout: {
      search: searchMod,
      list: listMod,
    },
  
    create: function(el,options)
    {
      this.modParams.collection = options.collection;
      
      this.modParams.tv_group = options.tv_group;
      
      return Layout.create.call(this,el,options);
    }, 

  });
});
