<template>
  <div>
  <SimpleTable ref=top10 name="iptv-top10" title="Топ 10 самых популярных каналов" :columns=columns divClass=""
  >
    <template #channel="{rec}">
       {{channelName(rec.channel)}}
    </template>

    <template #rate="{rec}">
       {{(rec.rate*100).toFixed(2)}} %
    </template>

  </SimpleTable>
  <br/>
  <SimpleTable ref=bottom10 name="iptv-bottom10" title="Самые неинтересные каналы" :columns=columns divClass=""
  >
    <template #channel="{rec}">
       {{channelName(rec.channel)}}
    </template>

    <template #rate="{rec}">
       {{(rec.rate*100).toFixed(2)}} %
    </template>

  </SimpleTable>
  </div>
</template>

<script>
import darsan from "darsan"
import okPromise from "common"
import SimpleTable from 'common/visual/SimpleTable.vue'

export default {
  name: "IptvChannelStat",

  components: { SimpleTable },
  
  data()
  {
    return {
      columns: [
          {
            name: "channel",
            title: "Название",
          },
          {
            name: "rate",
            title: "Популярность",
          },
      ],
      stat: [],
    }
  },
  
  async created()
  {
    await this.ensure("iptv-channel")
    this.stat = await darsan.get("", "client", "/iptv/statistics")
    this.$refs.top10.loadRows(this.stat.top10)
    this.$refs.bottom10.loadRows(this.stat.bottom10)
  },
  
  methods: {
    setState() {},
    
    channelName(ch)
    {
      const rec = this.$store.state.preload['/iptv-channel'].find(el => el.no==ch)
      return rec ? rec.name_ru : ch
    }
  },
  
}
</script>