define([
    'jquery',
    'underscore',
    'backboneRadix',
    'darsan',
    'common/visual/visual',
    'navigation',
    'common',

    'text-loader!iptv-channel/new/layout.tpl',

], function($, _, Backbone, darsan, visual, navigation, common, exRateAddTemplate){
    return Object.create(visual).extend({
        create: function(el,opt)
        {
            var me = this;
            visual.create.apply(me,arguments);
                
            var WorkerView = Backbone.TemplateView.extend({
                events: {
                  'change input[type="file"]': 'imageChange',
                  'click button': 'submitEntity',
                },

                template: exRateAddTemplate,
                common: common,

                initialize: function()
                {
                    this.compiled = _.template(this.template,null);
                },

                render: function()
                {
                    var _this = this;
                    var markup = this.compiled( {me: this.model.toJSON(), tv_group: me.options.tv_group, fileServer: darsan.makeUrl("", "file", "/public/iptv") } );
                    this.$el.html(markup);

                    return this;
                },
                
                async imageChange()
                {
                  const img = await this.checkLogo()
                  if (!img) return
                  this.$el.find("#logo-img").attr("src", img.src)
                },

                async checkLogo()
                {
                  const logo = me.$el.find("input#logo")
                  if (!logo[0].files.length) return

                  const file = logo[0].files[0]
          
                  if (file.type.substr(0, 6) != "image/")
                  {
                    common.notifyError("Логотип канала должен быть изображением")
                    return false
                  }
          
                  const img = new Image();
                  const objectUrl = window.URL.createObjectURL(file);
          
                  const promise = new Promise((resolve, reject) => 
                  {
                    img.onload = () => 
                    {
                      const width  = img.naturalWidth;
                      const height = img.naturalHeight; 

                      // Resolve promise with the width and height
                      resolve([width, height]); 
                    }
                  })

                  // Setting the source makes it start downloading and eventually call `onload`
                  img.src = objectUrl
          
                  const [w,h] = await promise
                  if (w!=100 || h!=100)
                  {
                    common.notifyError("Размер логотипа должен быть 100х100 пикселей")
                    return false
                  }

                  return img
                },

                async submitEntity(ev) 
                {
                    var me = this;

                    var data = this.getFormData( this.$el.find('form#main') );

                    opt.collection.add(me.model);
                    
                    me.model.savem(data)
                    .then( model =>
                    {
                      common.notify("IPTV канал создан");
                      me.$el.find("form#main")[0].reset();
                      
                      const id = model.get("id")
                        
                      const promises = [
                        me.addLogo(model.get("no")).then(() => common.notify("Логотип сохранен")),
                        darsan.post("", "iptv", "/iptv/smartup/channel", data).then(() => common.notify("Данные также записаны в базу SmartUp")),
                        darsan.put("", "iptv", `/astra/channel/${id}`, {}).then(() => common.notify("Обновлены записи в Астрах")),
                      ]

                      if (data.archive=="1")
                      {
                        promises.push( darsan.put("", "iptv", `/flussonic/channel/${id}`, {}).then(() => common.notify("Обновлен канал во Флюсонике")) )
                      }

                      return Promise.allSettled(promises)
                    })
                    .done(() =>
                    {
                      navigation.changeState( { _rest: "/" + id } )
                    })
                    .fail((view, xhr) =>
                    {
                      const [name, text] = darsan.namedError(xhr)
                      if (name)
                      {
                        me.$el.find("[name='"+name+"']").closest(".form-group").addClass("has-error");
                        me.$el.find("[name='"+name+"']").focus();
                        me.$el.find("#help-"+name).removeClass("hidden").html(text);
                      }
                    })
                },
                
                
                async addLogo(no)
                {
                  const logo = me.$el.find("input#logo")
                  if (!logo[0].files.length) return

                  const file = logo[0].files[0]
          
                  var formData = new FormData()
                  formData.append('file', file, no + ".png")
                  formData.append("replace", 1)
          
                  try {
                    await darsan.formData('', 'file', "/iptv", formData)
                  }
                  catch(err)
                  {
                    common.notify("Ошибка записи: " + err)
                  }
                },

                getFormData: function(form) {
                    var unindexed_array = form.serializeArray();
                    var indexed_array = {};

                    $.map(unindexed_array, function (n, i) {
                        if (n['value']) {
                            indexed_array[n['name']] = n['value'];
                        }
                    });

                    return indexed_array;
                },

            });

            me.view = new WorkerView({
                el: me.$el,
            });
        },
        
        setState: function(state)
        {
            var me = this;

            var entity = state._rest;
            me.state = me.state || {};

            me.state = state;

            var url = darsan.makeUrl("", "iptv", '/iptv/channel');

            var MyModel = Backbone.ModelEx.extend({
                idAttribute: "id",
                url: url,
            });

            var model = new MyModel();

            me.view.model = model;
            me.view.render();

        },

    });
});